import React from 'react';
import ReactDOM from 'react-dom';
import WOW from 'wow.js';
import Home from './views/Home';
import reportWebVitals from './reportWebVitals';
import './assets/css/main.css'

function App() {
	new WOW({
		boxClass: 'wow', // default
		animateClass: 'animated', // default
		offset: 0, // default
		mobile: true, // default
		live: true, // default
	}).init();
	return <Home />;
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import { Grid, Container } from "@material-ui/core";
import Header from "../../components/Header";
import img1 from "../../assets/img/image.png";
import "./styles.css";

export default function Home() {
  return (
    <>
      <Header />
      <div id="home">
        <section id="section1">
          <Container component="main" maxWidth="lg" id="streaming">
            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <div className=" full center">
                  <img src={img1} alt="img" className='full wow flipInY'  data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='1s' />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section id="section2">
          <Container component="main" maxWidth="lg" id="streaming">
            <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <div className="contenedor-streaming wow fadeInLeft"  data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
                  <iframe title="streaming" src="https://vimeo.com/event/2094352/embed" allow="autoplay; fullscreen" allowFullScreen className="estilo-streaming"></iframe>
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className="contentenedor-chat wow fadeInRight"  data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
                  <iframe id="video" title="chat" src="https://vimeo.com/event/2094352/chat/" width="100%" height="100%" frameBorder="0"></iframe>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
    </>
  );
}

import React from "react";
import { PropTypes } from "prop-types";
import Logo from '../../assets/img/spira-logo.svg';
import "./styles.css";

const Header = (props) => {
  return (
    <>
      <div id="myHeader" className={`header`}>
        <div className="full">
          <div className={`padre full`}>
           <img src={Logo} alt="logo" className='hijos' />
          </div>
        </div>
      </div>
    </>
  );
};
Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  bg: PropTypes.bool,
};
export default Header;
